import React, { useCallback, useMemo } from 'react';
import {
  ACCOUNT_PREFERENCES_URL,
  ACCOUNT_SETTINGS_URL,
  PRICING_PAGE_URL,
} from 'shared/constants.platform';
import { globalState } from 'shared/foreground/models';
import { createToast } from 'shared/foreground/toasts.platform';
import exportDocumentsToFile from 'shared/foreground/utils/exportDocumentsToFile';
import exportOPMLToFile from 'shared/foreground/utils/exportOPMLToFile';
import exportUploadedFiles from 'shared/foreground/utils/exportUploadedFiles';
import { SubscriptionProduct, SubscriptionProductDisplay } from 'shared/types';

import Button from '../Button';
import { AccountSettingsPage, Section } from './AccountSettingsPage';
import styles from './ProfilePage.module.css';

enum SectionType {
  Subscription = 'subscription',
  Profile = 'profile',
  ImportData = 'import_data',
  ExportData = 'export_data',
  DeleteAccount = 'delete_account',
}

export const ProfilePage = () => {
  return (
    <AccountSettingsPage title="Profile">
      <Subscription />
      <Profile />
      <ExportData />
      <DeleteAccount />
    </AccountSettingsPage>
  );
};

const Subscription = () => {
  const profile = globalState(useCallback((state) => state.client?.profile, []));
  const subscription = useMemo(() => profile?.subscription, [profile]);
  if (!subscription) {
    return null;
  }

  const canUpgrade = ![SubscriptionProduct.Full, SubscriptionProduct.Beta].includes(
    subscription.product,
  );

  const isExpired = subscription.product === SubscriptionProduct.Expired;
  const isOrWasTrial =
    subscription.product === SubscriptionProduct.Trial ||
    (isExpired && !subscription.was_previously_subscribed);

  let productName = SubscriptionProductDisplay[subscription.product];
  if (isOrWasTrial) {
    productName = SubscriptionProductDisplay[SubscriptionProduct.Trial];
  }
  return (
    <Section id={SectionType.Subscription} title="Subscription">
      <div className={styles.subscriptionContainer}>
        <div className={styles.subscriptionTitleRow}>
          <span className={styles.subscriptionTitle}>
            {productName}{' '}
            {isExpired && !subscription.was_previously_subscribed && (
              <span style={{ marginLeft: 6 }}>expired</span>
            )}
            {!isExpired && isOrWasTrial && (
              <span
                className={`${styles.timeRemaining} ${
                  subscription.trial_days_left <= 7 ? styles.timeRemainingEnding : ''
                }`}
              >
                {profile?.subscription?.trial_days_left} days left
              </span>
            )}
          </span>
          {!canUpgrade && (
            <a href={ACCOUNT_SETTINGS_URL} target="_blank" rel="noreferrer" data-open-on-desktop>
              Manage plan
            </a>
          )}
        </div>
        {isExpired && isOrWasTrial && (
          <span className={styles.subscriptionDescriptionRow}>
            Your free trial is now over. You&apos;ll need to upgrade to a Readwise Full plan to continue
            using Reader.
          </span>
        )}
        {!isExpired && isOrWasTrial && (
          <span className={styles.subscriptionDescriptionRow}>
            You have {subscription.trial_days_left} day{subscription.trial_days_left !== 1 ? `s` : ''}{' '}
            remaining in your free trial. You&apos;ll need to upgrade to a Readwise Full plan to continue
            using Reader thereafter.
          </span>
        )}
        {isExpired && !isOrWasTrial && (
          <span className={styles.subscriptionDescriptionRow}>
            Your subscription has expired. You&apos;ll need to renew your Readwise Full plan to continue
            using Reader.
          </span>
        )}
        {canUpgrade && (
          <a href={PRICING_PAGE_URL} target="_blank" rel="noreferrer">
            <Button variant="blue">Upgrade</Button>
          </a>
        )}
      </div>
    </Section>
  );
};

const Profile = () => {
  // const profileInitials = useProfileNameInitials();
  const profile = globalState(useCallback((state) => state.client?.profile, []));
  if (!profile) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, first_name } = profile;
  return (
    <Section id={SectionType.Profile} title="Details">
      <div className={styles.profileInfoContainer}>
        {/* <div className={styles.uploadPhotoWrapper}>
          <div className={styles.photo}>
            {profileInitials}
            <Button className={styles.trashWrapper} variant="unstyled">
              <TrashIcon />
            </Button>
          </div>
          <Button onClick={() => null} variant="default">
            Upload photo
          </Button>
        </div> */}
        <div className={styles.profileItem}>
          <label htmlFor="name">Name</label>
          <div>
            <input value={first_name} name="name" autoComplete="false" aria-label="name" disabled />
            <a href={ACCOUNT_SETTINGS_URL} target="_blank" rel="noreferrer" data-open-on-desktop>
              Change
            </a>
          </div>
        </div>
        <div className={styles.profileItem}>
          <label htmlFor="email">Email</label>
          <div>
            <input value={email} name="email" autoComplete="false" aria-label="email" disabled />
            <a href={ACCOUNT_PREFERENCES_URL} target="_blank" rel="noreferrer" data-open-on-desktop>
              Change
            </a>
          </div>
        </div>
        <div className={styles.profileItem}>
          <label htmlFor="password">Password</label>
          <div>
            <input
              value="1234567890"
              name="password"
              type="password"
              autoComplete="false"
              aria-label="password"
              disabled
            />
            <a href={ACCOUNT_SETTINGS_URL} target="_blank" rel="noreferrer" data-open-on-desktop>
              Change
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

const ExportData = () => {
  const exportOPML = useCallback(() => {
    exportOPMLToFile();
  }, []);
  const exportCSV = useCallback(() => {
    createToast({ category: 'default', content: 'Generating CSV of all documents...' });
    exportDocumentsToFile();
  }, []);
  const exportFiles = useCallback(() => {
    createToast({ category: 'default', content: 'Generating export of all uploaded files...' });
    exportUploadedFiles();
  }, []);
  return (
    <Section
      contentBoxClassName={styles.exportData}
      id={SectionType.ExportData}
      title="Export account data"
    >
      <Button onClick={exportCSV} variant="default">
        Export Library as CSV
      </Button>
      <Button onClick={exportOPML} variant="default">
        Export Feeds as OPML
      </Button>
      <Button onClick={exportFiles} variant="default">
        Export PDFs and EPUBs
      </Button>
    </Section>
  );
};

const DeleteAccount = () => {
  return (
    <Section
      contentBoxClassName={styles.deleteAccountContent}
      id={SectionType.DeleteAccount}
      title="Delete account"
      titleClassName={styles.deleteAccountTitle}
    >
      <p>
        Deleting your account will irreversibly delete all of your data, including your highlights, tags,
        notes, emails, and more.
      </p>
      <a href={ACCOUNT_SETTINGS_URL} target="_blank" rel="noreferrer" data-open-on-desktop>
        <Button variant="danger">Delete your Account</Button>
      </a>
    </Section>
  );
};
